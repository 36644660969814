import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
import { serialize } from 'object-to-formdata';
export const getProthesists = createAsyncThunk('prothesist/getProthesists', async ({ search, limit, page, startDate, endDate, }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search
            ? { limit, page, startDate, endDate }
            : startDate && endDate
                ? { search, limit, page, startDate, endDate }
                : { search, limit, page });
        const response = await apiKey.get(`/prothesists?${query}`);
        const data = response.data.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createProthesist = createAsyncThunk('prothesist/createProthesist', async ({ params }, ThunkApi) => {
    const { file, ...otherData } = params;
    try {
        const response = await apiKey.post(`/prothesists`, serialize(file ? { ...params } : { ...otherData }));
        const data = response.data.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateProthesist = createAsyncThunk('prothesist/updateProthesist', async ({ params, id }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/prothesists/${id}`, serialize({ ...params }));
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteProthesist = createAsyncThunk('prothesist/deleteProthesist', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.delete(`/prothesists/${id}`);
        const data = response.data.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const UpdateManyProthesists = createAsyncThunk('prothesist/UpdateManyProthesists', async ({ prothesists, labId }, ThunkApi) => {
    try {
        return await apiKey.post(`/prothesists/${labId}`, { prothesists });
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
